import { observer } from 'mobx-react-lite'
import React from 'react'
import { useAuth } from 'src/contexts'

import { useNavBarObj } from './hooks'
import NavbarLinkItem from './NavbarLinkItem'

const NavBarLinkContainer: React.FC = observer(() => {
  const { env, accountDetails } = useAuth()

  const navBarItems = useNavBarObj(accountDetails.accountType, env)
  return (
    <div className="flex w-full gap-x-6">
      {navBarItems.map((navBar, index) => (
        <NavbarLinkItem {...navBar} key={index} />
      ))}
    </div>
  )
})
export default NavBarLinkContainer
