import React from 'react'
import { marvelLogo } from '../../assets'

const staticLogoConfig = { logo: marvelLogo.toString(), logoSize: '100%' }

export function StaticLogo (): JSX.Element {
  return (
    <div className='flex items-center p-2 h-4 w-24 mt-4'>
      <img src={staticLogoConfig.logo} alt='Logo' />
    </div>
  )
}
