import { Auth } from '@aws-amplify/auth'
import { Hub } from '@aws-amplify/core'
import React, { ReactNode, useCallback, useEffect } from 'react'

interface AuthContextType {
  signOut: () => void
  hasLoaded: boolean
  isAuthenticated: boolean
  accountDetails: AccountDetails
  userAttributes: UserAttributes
  env: string
}

interface AccountDetails {
  accountType: string
  accountRole: string
}

export interface UserAttributes {
  accountOrganizationId: string | undefined
  accountOrganizationName: string | undefined
  userAccountRole: string | undefined
  userAccountType: string | undefined
  userEmail: string | undefined
  userFamilyName: string | undefined
  userGivenName: string | undefined
}

const AuthContext = React.createContext<AuthContextType>({
  signOut: () => {},
  hasLoaded: false,
  isAuthenticated: false,
  accountDetails: { accountType: '', accountRole: '' },
  userAttributes: {
    accountOrganizationId: '',
    accountOrganizationName: '',
    userAccountType: '',
    userAccountRole: '',
    userEmail: '',
    userFamilyName: '',
    userGivenName: '',
  },
  env: '',
})

export function AuthProvider(props?: {
  env: string
  children: ReactNode
  isMfe: boolean
}): JSX.Element {
  const env = props?.env ?? ''
  const isMfe = props?.isMfe ?? false
  const [hasLoaded, setHasLoaded] = React.useState<boolean>(false)
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>(false)
  const [accountDetails, setAccountDetails] = React.useState<AccountDetails>({
    accountType: '',
    accountRole: '',
  })
  const [userAttributes, setUserAttributes] = React.useState<UserAttributes>({
    accountOrganizationId: '',
    accountOrganizationName: '',
    userAccountType: '',
    userAccountRole: '',
    userEmail: '',
    userFamilyName: '',
    userGivenName: '',
  })

  const listener = useCallback(
    (data) => {
      const event = data?.payload?.event
      const userData = data?.payload?.data?.attributes ?? ''

      const accountType = data?.payload?.data?.attributes['custom:account_type']
      const accountRole = data?.payload?.data?.attributes['custom:account_role']

      const accountOrganizationId: string | undefined = userData['custom:organization_id']
      const accountOrganizationName: string | undefined = userData['custom:organization_name']
      const userAccountRole: string | undefined = userData['custom:account_role']
      const userAccountType: string | undefined = userData['custom:account_type']
      const userEmail: string | undefined = userData.email
      const userFamilyName: string | undefined = userData.family_name
      const userGivenName: string | undefined = userData.given_name

      switch (event) {
        case 'signIn':
          setIsAuthenticated(true)
          setAccountDetails({ accountType: accountType, accountRole: accountRole })
          setUserAttributes({
            accountOrganizationId: accountOrganizationId,
            accountOrganizationName: accountOrganizationName,
            userAccountType: userAccountType,
            userAccountRole: userAccountRole,
            userEmail: userEmail,
            userFamilyName: userFamilyName,
            userGivenName: userGivenName,
          })
          break
        case 'signOut':
          setIsAuthenticated(false)
          setAccountDetails({ accountType: '', accountRole: '' })
          setUserAttributes({
            accountOrganizationId: '',
            accountOrganizationName: '',
            userAccountType: '',
            userAccountRole: '',
            userEmail: '',
            userFamilyName: '',
            userGivenName: '',
          })
          break
      }
    },
    [setIsAuthenticated, setAccountDetails, setUserAttributes]
  )

  useEffect(() => {
    Hub.listen('auth', listener)

    async function updateAuthState(): Promise<void> {
      try {
        const { getSession } = isMfe
          ? await import('@pp/utils')
          : { getSession: async () => await Auth.currentSession() }
        const session = await getSession()
        const payload = session.getIdToken().payload
        setAccountDetails({
          accountType: payload['custom:account_type'],
          accountRole: payload['custom:account_role'],
        })
        setIsAuthenticated(session.isValid())
        setUserAttributes({
          accountOrganizationId: payload['custom:organization_id'],
          accountOrganizationName: payload['custom:organization_name'],
          userAccountType: payload['custom:account_type'],
          userAccountRole: payload['custom:account_role'],
          userEmail: payload.email,
          userFamilyName: payload.family_name,
          userGivenName: payload.given_name,
        })
      } catch (e) {
      } finally {
        setHasLoaded(true)
      }
    }

    if (!hasLoaded) {
      void updateAuthState()
    }

    return () => Hub.remove('auth', listener)
  }, [
    listener,
    hasLoaded,
    setIsAuthenticated,
    setHasLoaded,
    setAccountDetails,
    setUserAttributes,
    isMfe,
  ])

  const value = {
    signOut: async () => {
      const { signOut } = isMfe
        ? await import('@pp/utils')
        : { signOut: async () => await Auth.signOut() }
      await signOut()
      window.location.replace('/')
    },
    hasLoaded,
    isAuthenticated,
    accountDetails,
    userAttributes,
    env,
  }

  return <AuthContext.Provider value={value}>{props?.children}</AuthContext.Provider>
}

export function useAuth(): AuthContextType {
  return React.useContext(AuthContext)
}

export { AuthContext }
