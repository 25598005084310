export const FEATURES = {
  ENABLE_FE_NEW_ORDER_PAGE: 'ioi-enable_fe_new_order_page-rel-20220524',
  ENABLE_FE_DEAL_SETUP_PAGE: 'ioi-enable_fe_deal_setup_page-prod-20220524',
  ENABLE_ORDER_INDICATION_PAGE_IOI: 'ioi-enable_order_indications_page-prod-20220616',
  ENABLE_ORDER_VIEW_PAGE_IOI: 'ioi-enable_order_view_page-prod-20220719',
  ENABLE_EDIT_ORDER_INDICATION_PAGE_IOI: 'ioi-enable_edit_order_indication_page-prod-20220727',
  ENABLE_EMAIL_TO_SALES_TRADER_IOI: 'ioi-enable_email_to_sales_trader-prod-20220922',
  ENABLE_EMAIL_TO_INVESTOR_IOI: 'ioi-enable_email_to_investor-prod-20220921',
  ENABLE_PREFERENCES_PAGE_IOI: 'ioi-enable_preferences_page-prod-20221006',
  ENABLE_SELF_ACKNOWLEDGE_BUTTON_IOI: 'ioi-enable_self_acknowledge_button-prod-20221013',
  ENABLE_HISTORY_PAGE_IOI: 'ioi-enable_history_page-prod-20221013',
  ENABLE_REQUEST_ACCESS_BUTTON_IOI: 'ioi-enable_request_access-button-prod-20230110',
  ENABLE_REQUEST_ACCESS_PAGE_IOI: 'ioi-enable_request_access-page-prod-20230111',
  ENABLE_HOTJAR_TRACKING: 'ioi-hotjar-tracking-20221020',
  ENABLE_ALLOCATION: 'ioi-enable-allocation-20221125',
  ENABLE_NEW_URL_MUA: 'ioi-enable-url_prefix_of_target_page_to_mua_mfa-prod-20220212',
  ENABLE_FEEDBACK_FROM_ZOHO_BUTTON: 'ioi-enable_feedback_from_zoho_button-prod-20221213',
  ENABLE_TOOLTIP_ORDER_INDICATIONS_PAGE: 'ioi-enable_tooltip_order_indications_20231601',
  ENABLE_SECONDARY_NAVIGATION_IOI: 'ioi-enable_secondary_navigation-prod-20222003',
  ENABLE_MAIN_NAVIGATION_IOI: 'ioi-enable_main_navbar_21022023',
}
