import { HandleModalButtonArgType } from '@ppui/ui-components/dist/esm/components/ModalComponent/types'
import React, { useCallback, useState } from 'react'
import { useAuth } from 'src/contexts'

import { defaultZohoBody, ZOHO_URL } from './const'
import { ModalState } from './types'

export const useClickHandler = (): {
  setDescription: React.Dispatch<React.SetStateAction<string>>
  modalState: ModalState
  setModalState: React.Dispatch<React.SetStateAction<ModalState>>
  confirmHandler: (type: HandleModalButtonArgType) => void
} => {
  const { userAttributes } = useAuth()

  const [modalState, setModalState] = useState<ModalState>(null)
  const [description, setDescription] = useState('')
  const confirmHandler = useCallback(
    async (type) => {
      if (type !== 'ok') {
        setModalState(null)
        return
      }

      const XHR = new XMLHttpRequest()

      const urlEncodedDataPairs = Object.entries({
        ...defaultZohoBody,
        'First Name': userAttributes.userGivenName ?? '',
        'Last Name': userAttributes.userFamilyName ?? '',
        Email: userAttributes.userEmail ?? '',
        Company: userAttributes.accountOrganizationName ?? '',
        Description: description,
      }).map(([name, value]) => `${encodeURIComponent(name)}=${encodeURIComponent(value)}`)

      const urlEncodedData = urlEncodedDataPairs.join('&').replace(/%20/g, '+')
      new Promise((resolve, reject) => {
        // Define what happens on successful data submission
        XHR.addEventListener('load', (event) => {
          resolve(event)
        })

        // Define what happens in case of error
        XHR.addEventListener('error', (event) => {
          reject(event)
        })

        // Set up our request
        XHR.open('POST', ZOHO_URL)

        // Add the required HTTP header for form data POST requests
        XHR.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')

        // Finally, send our data.
        XHR.send(urlEncodedData)
      }).finally(() => {
        setModalState('success')
        setTimeout(() => {
          setModalState(null)
        }, 3000)
      })
    },
    [description, setModalState, userAttributes]
  )

  return {
    setDescription,
    confirmHandler,
    modalState,
    setModalState,
  }
}
