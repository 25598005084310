import { makeAutoObservable } from 'mobx'

import { RootStore } from '../rootStore'
import { DealSetupDto } from './types'

export class DealSetupStore {
  private readonly rootStore: RootStore
  private readonly responseWasReceived: boolean = false
  private dealSetupInstance: null | DealSetupDto = null

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  set dealSetup(value: DealSetupDto | null) {
    this.dealSetupInstance = value
  }

  get dealSetup(): DealSetupDto | null {
    return this.dealSetupInstance
  }

  reset(): void {
    this.dealSetupInstance = null
  }

  hasResponse(): boolean {
    return this.responseWasReceived
  }
}
