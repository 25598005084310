import React from 'react'
import { BundleStore } from 'src/store/bundleStore/bundleStore'
import { FeatureFlagsStore } from 'src/store/featureFlagsStore/featureFlagsStore'
import { MloStore } from 'src/store/mloStore/mloStore'
import { OrderStore } from 'src/store/orderStore/orderStore'

import { AlreadyActionedPanelStore } from './componentStateStore/alreadyActionedPanelStore'
import { SelfAcknowledgementContainerStore } from './componentStateStore/selfAcknowledgementContainerStore'
import { DealSetupStore } from './dealSetupStore/dealSetupStore'

export class RootStore {
  featureFlagsStore: FeatureFlagsStore
  dealSetupStore: DealSetupStore
  orderStore: OrderStore
  mloStore: MloStore
  bundleStore: BundleStore
  selfAcknowledgementContainerStore: SelfAcknowledgementContainerStore
  alreadyActionedPanelStore: AlreadyActionedPanelStore

  constructor() {
    this.featureFlagsStore = new FeatureFlagsStore(this)
    this.dealSetupStore = new DealSetupStore(this)
    this.orderStore = new OrderStore(this)
    this.mloStore = new MloStore(this)
    this.bundleStore = new BundleStore(this)
    this.selfAcknowledgementContainerStore = new SelfAcknowledgementContainerStore(this)
    this.alreadyActionedPanelStore = new AlreadyActionedPanelStore(this)
  }
}

export const rootStore = new RootStore()
const RootStoreContext = React.createContext(rootStore)
export const useStores = (): RootStore => React.useContext(RootStoreContext)
