import clsx from 'clsx'
import { observer } from 'mobx-react'
import React from 'react'
import { Link } from 'react-router-dom'
import { useStores } from 'src/store/rootStore'

import NavBarLinkWithPopup from './NavBarLinkWithPopup'
import { NavbarLinkItemProps } from './types'
import { isExitModalVisible } from './utils'

const NavbarLinkItem: React.FC<NavbarLinkItemProps> = observer(
  ({ label, to, icon, pathname, isAbsoluteURL }) => {
    const {
      orderStore: { isOrderDetailsEdited },
    } = useStores()
    const isModalVisible: boolean = isExitModalVisible(pathname, to)

    const labelStyles = clsx('font-semibold text-base', pathname === to ? 'text-primary' : '')
    const containerStyles = clsx(
      'flex gap-2 cursor-pointer items-center text-gray-900 px-2 hover:bg-gray-200 rounded-lg'
    )

    const content = (
      <>
        {icon != null && <span className="h-4 w-4">{icon}</span>}
        <h3 className={labelStyles}>{label}</h3>
      </>
    )

    if (isOrderDetailsEdited && isModalVisible) {
      return <NavBarLinkWithPopup to={to} content={content} isAbsoluteURL={isAbsoluteURL} />
    }

    return isAbsoluteURL ? (
      <a className={containerStyles} href={to}>
        {content}
      </a>
    ) : (
      <Link to={to} className={containerStyles}>
        {content}
      </Link>
    )
  }
)

export default React.memo(NavbarLinkItem)
