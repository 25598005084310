import { always, ifElse, pathOr, pipe, propOr } from 'ramda'

export const toKebabCase = (str: string): string => {
  return str
    .replace(/([a-z])([A-Z])/g, '$1-$2') // convert camel case to kebab case
    .replace(/[_\s]+/g, '-') // replace underscores and spaces with hyphens
    .toLowerCase() // convert to lowercase
}

export const parseJSON = (errorResponse: string): any => {
  try {
    return JSON.parse(errorResponse)
  } catch (e) {
    return { statusCode: 400 }
  }
}

export const isValidJSON = (errorResponse: string): boolean => {
  try {
    JSON.parse(errorResponse)
    return true
  } catch (e) {
    return false
  }
}

export const extractErrorCode = ifElse(
  pipe(pathOr('', ['request', 'response']), isValidJSON),
  pipe(pathOr('', ['request', 'response']), JSON.parse, propOr(undefined, 'statusCode')),
  always(undefined)
)
