import { useAuth } from 'src/contexts'
import { AccountType } from 'src/lib/types'

interface UseHeaderReturn {
  getUrl: () => string
  isInvestor: boolean
}

export const UseHeader = (): UseHeaderReturn => {
  const { env, isAuthenticated, userAttributes } = useAuth()

  const getUrl = (): string => {
    if (!isAuthenticated) {
      return '/'
    }

    return `https://${env}.primaryportal.com/ecm/dashboard`
  }

  const isInvestor: boolean = userAttributes.userAccountType === AccountType.INVESTOR

  return {
    getUrl,
    isInvestor,
  }
}
