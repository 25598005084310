import { UseHeader } from 'components/Header/UseHeader'
import { ClientLogo } from 'components/Logo/ClientLogo'
import NavBarLinkContainer from 'components/NavBar/NavBarLinkContainer'
import ProfileMenu from 'components/ProfileMenu'
import React from 'react'

const MainHeader: React.FC = () => {
  const { getUrl, isInvestor } = UseHeader()

  return (
    <nav className="shadow-md">
      <div className="mx-auto px-2 my-2">
        <div className="flex justify-between">
          <div className="flex ml-9">
            <a href={getUrl()}>
              <ClientLogo />
            </a>
            <NavBarLinkContainer />
          </div>
          <div className="flex space-x-7 items-center px-7">
            {isInvestor && (
              <a
                className="top-7 text-xs text-gray-700 font-bold"
                href="https://primary-portal.com/user-guide/"
                target="_blank"
                rel="noreferrer"
              >
                User Guide
              </a>
            )}
            <ProfileMenu />
          </div>
        </div>
      </div>
    </nav>
  )
}

export default React.memo(MainHeader) as typeof MainHeader
