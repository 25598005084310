import { useGetOrgById } from 'src/lib/queries/useGetOrgById'
import { useGetUserProfile } from '../../lib/queries/useGetUserProfile'

export const useGetBankLogo = (): { logo: string } => {
  const bankerOrgsQuery = useGetUserProfile()

  if (bankerOrgsQuery.isLoading) {
    return { logo: '' }
  }
  let logoURL = ''

  const organizationLogo = bankerOrgsQuery.data?.organization?.logo
  if (organizationLogo !== undefined) { logoURL = organizationLogo }

  return {
    logo: logoURL
  }
}

export const useGetBankIdLogo = (bankId: string): { logo: string } => {
  const orgsQuery = useGetOrgById(bankId)
  if (orgsQuery.isLoading) {
    return { logo: '' }
  }
  let logoURL = ''
  const bankLogo = orgsQuery.data?.logo?.url
  if (bankLogo !== undefined) { logoURL = bankLogo }

  return {
    logo: logoURL
  }
}
