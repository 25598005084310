import { MloEntity } from 'components/Orders/OrderForm/types'
import { makeAutoObservable } from 'mobx'
import { RootStore } from 'src/store/rootStore'

import { ORDER_STATUS } from '../../lib/types'

export class MloStore {
  private readonly rootStore: RootStore
  private mloInstance: MloEntity | null = null

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  set mlo(value: MloEntity | null) {
    this.mloInstance = value
  }

  get mlo(): MloEntity | null {
    return this.mloInstance
  }

  reset(): void {
    this.mloInstance = null
  }

  updateOrder(orderId: string): void {
    if (this.mloInstance !== null) {
      this.mloInstance.banks = this.mloInstance.banks.map((mloBank) =>
        mloBank.orderId === orderId
          ? {
              ...mloBank,
              status: ORDER_STATUS.ACKNOWLEDGED,
            }
          : mloBank
      )
    }
  }
}
