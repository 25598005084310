import { always, and, cond, isEmpty } from 'ramda'
import React, { useMemo, useState } from 'react'
import { useAuth } from 'src/contexts'

const ProfileMenu = (): JSX.Element => {
  const { userAttributes, signOut } = useAuth()
  const [isOpenProfileMenu, setIsOpenProfileMenu] = useState(false)
  const toggleProfile = (): void => {
    setIsOpenProfileMenu(!isOpenProfileMenu)
  }

  const userGivenName = userAttributes.userGivenName ?? ''
  const userFamilyName = userAttributes.userFamilyName ?? ''
  const userEmail = userAttributes.userEmail ?? ''

  const initials = useMemo(
    () =>
      cond([
        [
          () => and(!isEmpty(userGivenName), !isEmpty(userFamilyName)),
          always(`${userGivenName[0]}${userFamilyName[0]}`),
        ],
        [
          () => and(!isEmpty(userGivenName), isEmpty(userFamilyName)),
          always(`${userGivenName[0]}${userGivenName[1]}`),
        ],
        [
          () => and(isEmpty(userGivenName), !isEmpty(userFamilyName)),
          always(`${userFamilyName[0]}${userFamilyName[1]}`),
        ],
        [() => !isEmpty(userEmail), always(`${userEmail[0]}${userEmail[1]}`)],
      ]),
    [userEmail, userFamilyName, userGivenName]
  )

  return (
    <>
      <div className="md:flex items-center space-x-3">
        <button
          type="button"
          className="p-2 mr-4 font-mono font-semibold text-xs text-white bg-gray-800 hover:bg-gray-900 rounded-full transition duration-300"
          id="user-menu-button"
          aria-expanded="false"
          aria-haspopup="true"
          onClick={toggleProfile}
          data-qa="ioi.profile-menu.initial-buttons"
        >
          {initials()?.toLowerCase()}
        </button>
      </div>
      {isOpenProfileMenu && (
        <div
          className=" origin-top-right absolute top-16 right-14 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-1500"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="user-menu-button"
          tabIndex={-1}
        >
          <ul>
            <li>
              <button
                className="block px-4 py-2 text-sm text-gray-700 z-50"
                onClick={signOut}
                data-qa="ioi.profile-menu.logout-button"
              >
                Logout
              </button>
            </li>
          </ul>
        </div>
      )}
    </>
  )
}

export default React.memo(ProfileMenu)
