import {
  Cog8ToothIcon,
  CursorArrowRaysIcon,
  ShieldExclamationIcon,
} from '@heroicons/react/24/outline'
import { ModalConfirm, SecondaryNavigationBar, SecondaryNavigationItem } from '@ppui/ui-components'
import { HandleModalButtonArgType } from '@ppui/ui-components/dist/cjs/components/ModalComponent/types'
import { isExitModalVisible } from 'components/NavBar/utils'
import { observer } from 'mobx-react'
import React, { useCallback, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { CONFIRMATION_EXIT_MESSAGE } from 'src/routes/EditOrder/constants'
import { useStores } from 'src/store/rootStore'

const SecondaryHeader: React.FC = observer(() => {
  const {
    orderStore: { isOrderDetailsEdited },
  } = useStores()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [url, setUrl] = useState('')

  const location = useLocation()
  const navigate = useNavigate()

  const handleButtonClick = useCallback(
    (type: HandleModalButtonArgType) => {
      if (type === 'ok') {
        // remove listener to popState event before page gets navigated, to avoid exit alert
        window.onpopstate = null
        navigate(url)
      }
      setIsOpen(false)
    },
    [navigate, url]
  )

  const onClickHandler = (to: string): void => {
    const isModalVisible: boolean = isExitModalVisible(location.pathname, to)

    // if changes were made and page supports exit warning, show exit alert, if no, redirect
    if (isOrderDetailsEdited && isModalVisible) {
      setIsOpen(true)
      setUrl(to)
      return
    }
    navigate(to)
  }

  return (
    <div className="flex flex-grow flex-col max-w-[1280px] mx-auto">
      <SecondaryNavigationBar>
        <SecondaryNavigationItem
          clickHandler={() => onClickHandler('/order-indications')}
          icon={<CursorArrowRaysIcon className="mt-0.5 w-4 h-4" />}
          isActive={location.pathname === '/order-indications'}
          text="Order indications overview"
          dataQa="ioi.secondary-header.order-indications"
        />

        <SecondaryNavigationItem
          clickHandler={() => onClickHandler('/preferences')}
          icon={<Cog8ToothIcon className="mt-0.5 w-4 h-4" />}
          isActive={location.pathname === '/preferences'}
          text="Preferences"
          dataQa="ioi.secondary-header.preferences"
        />
      </SecondaryNavigationBar>

      <ModalConfirm
        isOpen={isOpen}
        size="normal"
        title="Leave this page"
        labels={{
          success: 'Exit editing',
          cancel: 'Keep editing',
        }}
        icon={<ShieldExclamationIcon className="h-6 w-6 text-amber-700" />}
        handleButtonClick={handleButtonClick}
        content={<>{CONFIRMATION_EXIT_MESSAGE}</>}
      />
    </div>
  )
})

export default React.memo(SecondaryHeader) as typeof SecondaryHeader
