/* eslint-disable react-hooks/rules-of-hooks */
import {
  ArrowPathIcon,
  Cog8ToothIcon,
  CursorArrowRaysIcon,
  Squares2X2Icon,
  UsersIcon,
} from '@heroicons/react/24/outline'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { AccountType } from 'src/lib/types'

import { NavbarLinkItemProps } from './types'
import { ecmLinks } from './utils'

export const useNavBarObj = (accType: string, env: string): NavbarLinkItemProps[] => {
  if (accType === AccountType.INVESTOR) {
    return useInvestorNavbar(env)
  }
  return useNotInvestorNavBar(env)
}

export const useInvestorNavbar = (env: string): NavbarLinkItemProps[] => {
  const location = useLocation()

  return [
    {
      isAbsoluteURL: true,
      label: 'Deal Dashboard',
      to: ecmLinks(env, 'dashboard'),
      pathname: location.pathname,
      icon: <Squares2X2Icon />,
    },
    {
      isAbsoluteURL: true,
      label: 'Contacts',
      to: ecmLinks(env, 'contacts'),
      pathname: location.pathname,
      icon: <UsersIcon />,
    },
    {
      isAbsoluteURL: true,
      label: 'My Relationships',
      to: ecmLinks(env, 'relationships'),
      pathname: location.pathname,
      icon: <ArrowPathIcon />,
    },
    {
      isAbsoluteURL: false,
      label: 'Order Indications',
      to: '/order-indications',
      pathname: location.pathname,
      icon: (
        <CursorArrowRaysIcon
          className={`${location.pathname === '/order-indications' ? 'text-primary' : ''}`}
        />
      ),
    },
    {
      isAbsoluteURL: false,
      label: 'Preferences',
      to: '/preferences',
      pathname: location.pathname,
      icon: (
        <Cog8ToothIcon
          className={`${location.pathname === '/preferences' ? 'text-primary' : ''}`}
        />
      ),
    },
  ]
}

export const useNotInvestorNavBar = (env: string): NavbarLinkItemProps[] => {
  const location = useLocation()

  return [
    {
      isAbsoluteURL: true,
      label: 'Deal Dashboard',
      to: `https://${env}.primaryportal.com/ecm/dashboard`,
      pathname: location.pathname,
      icon: <Squares2X2Icon />,
    },
    {
      isAbsoluteURL: false,
      label: 'Order Indications',
      to: '/order-indications',
      pathname: location.pathname,
      icon: (
        <CursorArrowRaysIcon
          className={`${location.pathname === '/order-indications' ? 'text-primary' : ''}`}
        />
      ),
    },
  ]
}
