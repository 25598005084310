import { StaticLogo } from 'components/Logo/StaticLogo'
import { isNilOrEmpty } from 'ramda-adjunct'
import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { useAuth } from 'src/contexts'

import { useGetBankLogo } from './hooks'

const queryClient = new QueryClient()

function ClientLogoElem(): JSX.Element {
  const { logo } = useGetBankLogo()
  const { isAuthenticated } = useAuth()
  if (isNilOrEmpty(logo)) {
    return <></>
  }

  if (!isAuthenticated) {
    return <StaticLogo />
  }

  return (
    <div className="flex h-55 w-170">
      <img src={logo} alt="Logo" className="object-contain" />
    </div>
  )
}

export function ClientLogo(): JSX.Element {
  return (
    <QueryClientProvider client={queryClient}>
      <ClientLogoElem />
    </QueryClientProvider>
  )
}
