import { ShieldExclamationIcon } from '@heroicons/react/24/outline'
import { ModalConfirm } from '@ppui/ui-components'
import { HandleModalButtonArgType } from '@ppui/ui-components/dist/esm/components/ModalComponent/types'
import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { alertUser } from 'src/routes/EditOrder/utils'

import { CONFIRMATION_EXIT_MESSAGE } from '../../routes/EditOrder/constants'
import { NavBarLinkWithPopupProps } from './types'

const NavBarLinkWithPopup: React.FC<NavBarLinkWithPopupProps> = ({
  content,
  to,
  isAbsoluteURL,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const navigate = useNavigate()
  const handleNavBarAction = useCallback(() => {
    isAbsoluteURL ? window.location.replace(to) : navigate(to, { replace: true })
  }, [isAbsoluteURL, to, navigate])

  const handleButtonClick = useCallback(
    (type: HandleModalButtonArgType) => {
      if (type === 'ok') {
        window.removeEventListener('beforeunload', alertUser)
        handleNavBarAction()
      }
      setIsOpen(false)
    },
    [handleNavBarAction]
  )

  return (
    <>
      <span
        onClick={() => setIsOpen(true)}
        className="flex gap-2 cursor-pointer items-center text-gray-900 px-2 hover:bg-gray-200 rounded-lg"
      >
        {content}
      </span>

      <ModalConfirm
        isOpen={isOpen}
        size="normal"
        title="Leave this page"
        labels={{
          success: 'Exit editing',
          cancel: 'Keep editing',
        }}
        icon={<ShieldExclamationIcon className="h-6 w-6 text-amber-700" />}
        handleButtonClick={handleButtonClick}
        content={<>{CONFIRMATION_EXIT_MESSAGE}</>}
      />
    </>
  )
}

export default React.memo(NavBarLinkWithPopup)
