import { findFirst } from 'fp-ts/Array'
import { flow, pipe } from 'fp-ts/function'
import { chain, chainNullableK, flatten, fromNullable, Option } from 'fp-ts/Option'
import { DateTime } from 'luxon'
import {
  always,
  cond,
  converge,
  curry,
  equals,
  gt,
  head,
  identity,
  ifElse,
  last,
  map,
  prop,
  T,
} from 'ramda'
import { Row } from 'react-table'
import { ColumnType } from 'src/lib/types'
import { MloSetupDto } from 'src/routes/EditOrder/mlo-setup.types'
import { Currency } from 'src/store/commonTypes'
import { DealSetupCurrency } from 'src/store/dealSetupStore/types'

// example: 18-Oct-2022 15:43 UTC
export const getFormattedDateEnteredBy = (val: string): string => {
  return `${DateTime.fromJSDate(new Date(val)).toUTC().toFormat('dd-LLL-y, HH:mm')}`
}

// example: 30 Aug 2022
export const getFormattedDate = (value: string): string =>
  DateTime.fromJSDate(new Date(value)).toUTC().toFormat('d LLL y')

export const getValidCurrency = (mloSetup: MloSetupDto): Option<Currency> =>
  pipe(
    fromNullable(mloSetup.currency),
    chainNullableK(
      flow(
        findFirst((currency: DealSetupCurrency) => currency.acceptOrderSubmission),
        chain(flow(prop('currencyName'), fromNullable))
      )
    ),
    flatten
  )

export const removeUndefined = <T>(object: T): T => JSON.parse(JSON.stringify(object))

export const sortColumn = curry(
  (columnType: ColumnType, columnName: string, rowA: Row, rowB: Row) =>
    pipe(
      [rowA, rowB],
      map((row: Row) =>
        cond([
          [
            equals(ColumnType.DATE_ISO),
            always(
              ifElse(
                isNaN,
                always(0),
                identity
              )(DateTime.fromISO(row.values?.[columnName]).toMillis())
            ),
          ],
          [equals(ColumnType.TEXT), always(row.values?.[columnName]?.toLowerCase())],
          [T, always(row.values?.[columnName])],
        ])(columnType)
      ),
      ifElse(
        converge(curry(equals), [head, last]),
        always(identity),
        ifElse(converge(curry(gt), [head, last]), always(1), always(-1))
      )
    )
)

export const isAppEnabled = (enabledApplications: string[] | undefined): boolean =>
  enabledApplications?.map((appName) => appName.toLowerCase()).includes('ioi') ?? false
