import { LoadingScreen } from '@ppui/ui-components'
import { AppContainer } from 'components/AppContainer'
import FeedbackZoho from 'components/FeedbackZoho/FeedbackZoho'
import { observer } from 'mobx-react'
import { isFalse } from 'ramda-adjunct'
import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { FEATURES } from './constants/features'
import { useAuth } from './contexts'
import { useGetUserProfile } from './lib/queries/useGetUserProfile'
import { isAppEnabled } from './lib/utils'
import { Retry } from './Retry'
import { useStores } from './store/rootStore'

const NotFound = React.lazy(async () => await Retry(async () => await import('./routes/NotFound')))

export const RequireAuth = observer(
  ({ children, featureFlag }: { children: JSX.Element; featureFlag?: string }): JSX.Element => {
    const { featureFlagsStore } = useStores()
    const isRequestAccessPageEnabled: boolean = featureFlagsStore.isFeatureFlagEnabled(
      FEATURES.ENABLE_REQUEST_ACCESS_PAGE_IOI
    )
    const { isAuthenticated, hasLoaded, accountDetails } = useAuth()
    const { data, isLoading } = useGetUserProfile()
    const location = useLocation()

    if (!hasLoaded) {
      return <LoadingScreen />
    }

    if (
      !isAuthenticated ||
      accountDetails.accountRole === '' ||
      accountDetails.accountType === ''
    ) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return (
        <Navigate
          to={`/login?${location?.pathname.slice(1) ?? ''}${location?.search}` ?? ''}
          replace
        />
      )
    }

    if (isLoading) {
      return <LoadingScreen />
    }

    // Redirect to Request access page if user is not enabled to IOI
    if (isFalse(isAppEnabled(data?.user?.applicationsEnabled)) && isRequestAccessPageEnabled) {
      return <Navigate to="/request-access" replace />
    }

    const responseWasReceived = featureFlagsStore.hasResponse()
    const isPageEnabled =
      featureFlag !== undefined ? featureFlagsStore.isFeatureFlagEnabled(featureFlag) : true

    const isFeedbackFromZohoButtonEnabled: boolean = featureFlagsStore.isFeatureFlagEnabled(
      FEATURES.ENABLE_FEEDBACK_FROM_ZOHO_BUTTON
    )

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!responseWasReceived) {
      return <LoadingScreen />
    }

    if (!isPageEnabled) {
      return <NotFound />
    }

    return (
      <AppContainer isAuthenticated>
        <div>
          {isFeedbackFromZohoButtonEnabled && <FeedbackZoho />}
          {children}
        </div>
      </AppContainer>
    )
  }
)
