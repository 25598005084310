import { Feedback, IconGeneral, Modal, ModalConfirm, TextArea } from '@ppui/ui-components'
import { observer } from 'mobx-react'
import { pathOr } from 'ramda'
import React from 'react'

import { useClickHandler } from './hooks'

const FeedbackZoho: React.FC = observer(() => {
  const { modalState, confirmHandler, setDescription, setModalState } = useClickHandler()

  return (
    <>
      <ModalConfirm
        title="Ask a question or provide feedback"
        isOpen={modalState === 'confirm'}
        labels={{
          success: 'Submit',
          cancel: 'Cancel',
        }}
        handleButtonClick={confirmHandler}
        size="normal"
        content={
          <TextArea
            name="feedback"
            onChange={(event) => setDescription(pathOr('', ['target', 'value'], event))}
          />
        }
      />
      <Modal
        isOpen={modalState === 'success'}
        handleCloseClick={() => setModalState(null)}
        content={
          <div className="text-center flex flex-col gap-y-2 items-center">
            <span className="pb-2">
              <IconGeneral variant="Envelope" />
            </span>
            <p className="text-gray-600 text-2xl">Thank You!</p>
            <p className="text-gray-500 text-base">Our team will be in touch shortly</p>
          </div>
        }
      />
      <Feedback
        clickHandler={() => setModalState('confirm')}
        containerStyles={{ right: '-right-[72px]' }}
      />
    </>
  )
})

export default FeedbackZoho
